<template>
  <validation-observer ref="formObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Dane przedstawiciela</h4>

        <b-form-group label="Imię" label-for="v-name">
          <validation-provider name="Imię" rules="required|min:2|max:36" v-slot="{ errors }">
            <b-form-input id="v-name" placeholder="Imię" v-model="form.name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Nazwisko" label-for="v-surname">
          <validation-provider name="Nazwisko" rules="required|min:2|max:36" v-slot="{ errors }">
            <b-form-input id="v-surname" placeholder="Nazwisko" v-model="form.surname" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Email" label-for="v-email">
          <validation-provider name="Email" rules="required|email" v-slot="{ errors }">
            <b-form-input id="v-email" placeholder="Email" v-model="form.email" type="email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Kraje" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            multiple
            :clearable="true"
            :close-on-select="false"
            placeholder="Wybierz kraje"
            v-model="form.countries"
            :options="allCountries" />
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all as allCountries } from '@/constants/enums/countries';

export default {
  mixins: [NotificationMixin],
  props: {
    wholesaleRepresentative: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.wholesaleRepresentative,
      allCountries,
    };
  },

  watch: {
    wholesaleRepresentative(newValue) {
      if (!newValue) return;

      this.form = newValue;
    },
  },

  methods: {
    onSubmit() {
      const countries = this.form.countries.map((country) => country.key);

      this.$emit('onSubmit', {
        ...this.form,
        countries,
      });
    },
    validationForm() {
      this.$refs.formObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
